<template>
  <div class="FinancialManagement">
    <div
      class="FinancialManagement_left"
      :class="{ FinancialManagement_left1: show == false }"
    >
      <el-menu class="el_menu" :default-active="$route.path" router v-show="show">
        <el-menu-item
          v-for="(item, index) in nav_menu_data"
          :key="index"
          :index="item.path"
        >
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="FinancialManagement_center">
      <img src="../../assets/image/guan.png" @click="close" v-show="show"/>
      <img src="../../assets/image/kai.png" @click="open" v-show="!show"/>
    </div>
    <div
      class="FinancialManagement_right"
      :class="{ FinancialManagement_right1: show == false }"
    >
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      show: true,
      nav_menu_data: [
        {
          title: "系统设置",
          path: "/Systemsettings",
        }
      ],
    }
  },
  created(){
this.$store.commit("nodekeydata", 1);
  },
  methods:{
    close() {//关
      this.show = false;
    },
    open(){//开
      this.show = true;
    }
  },
}
</script>
<style lang="scss" scoped>
.FinancialManagement {
  width: 1920px;
  height: 100%;
  .FinancialManagement_left {
    width: 140px;
    height: 100%;
    float: left;
    transition: width 1s;
    background-color: white;
    box-shadow: 3px 0 6px rgba(0, 104, 163, 0.1);
    .el_menu{
      width: 140px;
      span {
        font-size: 18px;
      }
    }
  }
  .FinancialManagement_left1 {
    width: 0px;
  }
  .FinancialManagement_center {
    width: 30px;
    height: 100%;
    //background: chartreuse;
    float: left;
    position: relative;
    img {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 45%;
    }
  }
  .FinancialManagement_right {
    width: 1750px;
    height: 100%;
    float: left;
    transition: width 1s;
  }
  .FinancialManagement_right1 {
    width: 1890px;
  }
}
/deep/ .el-menu-item.is-active {
  border-left: 4px solid #409eff;
  color: #303133;
  padding-left: 16px!important;
}
</style>
